import * as React from 'react';
import {StaticImage} from 'gatsby-plugin-image';
import Footer from "../components/Footer";
import Layout from "../components/Layout";

const IndexPage = () => {
    return (
        <Layout pageTitle='KTKT LTD'>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}>
                <div style={{
                    alignSelf: 'center',
                    marginTop: '20px'
                }}>
                    <StaticImage
                        style={{
                            maxWidth: '30vw',
                            display: 'block',
                        }}
                        placeholder='tracedSVG'
                        src="../images/logo.png"
                        alt="KTKT LTD logo"
                    />
                </div>
                <div style={{
                    alignSelf: 'center',
                }}>
                    <Footer/>
                </div>
            </div>
        </Layout>
    )
}

export default IndexPage
